<template>
	<!--空间管理-->
	<mainBox mClass='spaceBox'>
		<div class="wapBox">
			<div class="flexs left">
				<div class="l_title">
					<div class="w50">有偿服务类型</div>
					<div class="w50 evts" @click='toAddServeTypes'>
						<span class="i_add" >+</span> 添加类别
					</div>
				</div>
				<div class="treeWarp">
					<ul>
						<li class="_iType" v-for="(it,dex) in treeData" :key='dex' :class="{txBlue:it.serviceTypeId==actvedNode.serviceTypeId}" @click="changeTableRows(it)">
							{{it.serviceTypeName}}
							<i @click.stop="toAddServeTypes(it)" class="el-icon-edit-outline _edicon"></i>
							<i @click.stop="toDelServeTypes(it)" class="el-icon-delete _delicon"></i>
						</li>
					</ul>
				</div>
			</div>
			<div class="flexs right">
				<div class="p_name" v-if="actvedNode">{{actvedNode.serviceTypeName}}</div>
				<div class="p_name" v-else>-</div>
				<div class="eBtns">
					<el-row>
			      <el-col :span="8">
			        <el-button type="primary" size="small" @click="toAddTbRow">添加服务项</el-button>
			        <el-button  size="small" plain @click='exportHandler'>导出</el-button><!-- 导出 -->
			      </el-col>
			      <el-col :span="12" :offset="4">
			        <div class="filter-container" align="right">
			          <el-input :placeholder="$t('commons.searchPhrase')" v-model='serviceContent' clearable size="small" style="width: 200px"/>
			          <el-button size="small" type="primary" icon="el-icon-search" @click='initTable'>搜索</el-button>
			        </div>
			      </el-col>
			    </el-row>
				</div>
				<div class="tbBox ">
					<div class="table-body">
					<TTable ref="pointTable" :tbloading='tbloading'  :tableData="tableData"  :tableTitle="tableTitle" :tbParams="tableParams">
						<template slot='operating' slot-scope="scope">
							<el-button type="text" @click.stop="tbRowEdit(scope.row)" >编辑</el-button>
							<el-button type="text" @click.stop="tbRowDel(scope.row)" >删除</el-button>
						</template>
					</TTable>
				</div>
				<TPagination v-model="tableParams" @initTable="initTable" ></TPagination><!-- 分页 -->
				</div>
			</div>
			<AddServiceType  :isOpen='typeInfo.open' @close='closeAddCompany' :dataId='typeInfo.dataId' :dataName='typeInfo.dataName'/>
			<addOrEdit v-if='addInfo.open' :isOpen='addInfo.open' @close='closeAddPosition' :dataId='operateRow.paidServiceId' :serviceTypeId='actvedNode.serviceTypeId' />
		</div>
	</mainBox>
</template>
<script>
	import pagination from "@/mixins/TTable/pagination";
	import seachAndTable from "@/mixins/TTable/seachAndTable";
	import TTable from '@/components/YTable/TTable.vue';
	import TPagination from '@/components/YTable/TPagination.vue';
	import AddServiceType from './model/AddServiceType.vue'
	import addOrEdit from './model/addOrEdit.vue'
	import { envInfo } from "@/constants/envInfo";
	import {downEmployeeTemplate} from "@/api/business/base/tenant/position";
	export default {
		mixins: [pagination,seachAndTable],
		components: {TTable,TPagination,AddServiceType,addOrEdit},
		data() {
			return {
				treeData:[
					{
		        "createdBy": 1367,
		        "creationDate": "2023-10-16T14:22:32.000+0800",
		        "lastUpdatedBy": 1367,
		        "lastUpdateDate": "2023-10-16T14:22:32.000+0800",
		        "description": null,
		        "serviceTypeId": 1,
		        "serviceTypeName": "Ych-1016-01",
		        "paidServiceVOS": []
			   },
				],
				treeProps:{
					label: 'projectName',
					children: 'projectChildren',
				},
				tableTitle:[
					{name:'服务项目',prop:"serviceContent"},
					{name:'单项收费金额（*元）',prop:"price"},
					{name:'计量单位',prop:"serviceUnit"},
					{name:'更新日期',prop:"lastUpdateDateStr"},
			    {name:'操作',width:"180",fixed:'right',slotName:"operating",},
				],
				tableData:[],
      	typeInfo:{//新增，编辑公司的弹框信息
      		open:false,
      		dataId:'',
      		dataName:'',
      	},
      	addInfo:{//新增，编辑位置的弹框信息
      		open:false,
      		dataId:'',
      	},
      	actvedNode:{
      		serviceTypeId:''
      	},//选中的项目节点  用于查询table数据
      	operateRow:{},
      	isTbAll:false,
      	serviceContent:'',
			};
		},
		created() {},
		mounted() {
			this.initServerTree();
		},
		computed: {},
		methods: {
			
			async initServerTree(){
				//获取所有有偿服务类型数据
				let params={
					isloading:false
				}
				let res = await this.ApiHttp('/base/tenant/paidService/findServiceType',params,'GET');
				console.log(res)
				this.treeData=res||[];
				if(res.length>0){
					this.actvedNode={...res[0]};
					this.initTable();
				}
			},
			
			exportHandler() {
				//导出
		      	const params = {
		      		serviceTypeId:this.actvedNode.serviceTypeId,
							serviceContent:this.serviceContent,
		      		...this.seachData,
							...this.tableParams,
		      	};
//		      	console.log('111',params)
		      	this.ApiHttp('/base/tenant/paidService/export',params).then((taskId) => {
			        this.$message({
			          	type: "success",
			          	message: '操作成功',
			        });
			        let exportObj = {
			          	taskId,
			          	taskName: "有偿服务项",
			          	taskStatus: 0,
			          	rootPath: "organizationPath",
			        };
			        //将导出任务丢入导出任务列表中
			        //异步存储
			        this.$store.dispatch("PushExportNotice", exportObj);
		      	});
		    },
		   	changeTableRows(it){
		   		this.actvedNode={...it};
		    	this.initTable();
		    },
			async initTable(){
				if(!this.actvedNode.serviceTypeId) return;
				this.tbloading=true;
				let params={
					isloading:false,
					serviceTypeId:this.actvedNode.serviceTypeId,
					serviceContent:this.serviceContent,
					current: this.tableParams.current,
					rowCount: this.tableParams.rowCount,
				}
				
				let res = await this.ApiHttp('/base/tenant/paidService/findPaidService',params);
				this.isTbAll=false;
				console.log('55555555',res)
				res.rows.map(it=>{
					if(it.price==0){
						it.price='0'
					}
				})
				this.tableData = res.rows||[];
				this.tableParams.total = res.total;
				this.$nextTick(()=>{
	  				this.tbloading=false;
	  			})
			},
			
			
			tbRowAddOrEdit(row,pid=''){
				
				this.operateRow={...row};
				console.log('445554',this.operateRow)
				this.addInfo={
					dataId:pid,
					open:true,
				}
			},
			toAddTbRow(){
				//点击打开弹窗
				let data={
					positionId:'',
					positionName:"",
				}
//				if(this.actvedNode) data.positionName=this.actvedNode.projectName;//获取上级名称
				this.tbRowAddOrEdit(data);
			},
			tbRowEdit(row){
				this.tbRowAddOrEdit(row)
			},
			tbRowDel(row){
				this.$confirm('是否确认删除', '提示').then(() => {
					let params={
						paidServiceId:row.paidServiceId,
					}
					this.ApiHttp('/base/tenant/paidService/deletePaidService',params,'delete').then(res=>{
						if(res){
							this.initTable();
						}
					});
					
				}).catch(() => {});
			},
			closeAddPosition(isInit){
				//关闭项目弹框
				this.addInfo={
		      		open:false,
		      		dataId:'',
		      	};
		      	if(isInit){
		      		this.initTable();
		      	}
			},
			
			toAddServeTypes(datas){
				//点击打开弹窗新增或修改服务类型
				console.log('11111',456456)
				this.typeInfo={
	      		open:true,
	      		dataId:datas?datas.serviceTypeId:'',
	      		dataName:datas?datas.serviceTypeName:'',
	      	};
			},
			toDelServeTypes(datas){
				
				this.$confirm('是否确认删除', '提示').then(() => {
					let params={
					 serviceTypeId:datas.serviceTypeId,
					}
					this.ApiHttp('/base/tenant/paidService/deleteServiceType',params,'delete').then(res=>{
						this.initServerTree();
					});
				}).catch(() => {});
				
			},
			closeAddCompany(isInit){
				//关闭项目弹框
				this.typeInfo={
      		open:false,
      		dataId:'',
      		dataName:'',
      	};
      	if(isInit){
      		this.initServerTree();
      	}
			},
			//公司相关 end
		},
	};
</script>

<style scoped lang="scss">

	.wapBox{
		height: 100%;
		display: flex;
		
		.flexs{
			height: 100%;
		}
		.left{
			width: 320px;
			border-right: 1px solid #EEEEEE;
		}
		.l_title{
			padding: 12px 0;
			border-bottom: 1px solid #EBEEF5;
			color: #2F3941;
			display: flex;
			.w50{
				font-weight: 600;
				width: 50%;
				padding: 0 16px;
				line-height: 36px;
			}
			.evts{
				cursor: pointer;
				font-weight: 400;
				text-align: right;
				color: #4E5969;
			}
		}
		.treeWarp{
			padding: 12px 16px;
			box-sizing: border-box;
			height: calc(100% - 62px);
    	overflow-y: auto;
    	._iType{
    		position: relative;
    		padding-left: 12px;
    		line-height: 40px;
    		._edicon,._delicon{
    			cursor: pointer;
    			position: absolute;
    			right: 6px;
    			font-size: 18px;
    			top: 8px;
    			display: none;
    		}
    		._delicon{
    			position: absolute;
    			right: 26px;
    		}
    	}
    	._iType:hover{
    		background: rgb(224,240,255);
    		._edicon,._delicon{
    			display: block;
    		}
    	}
		}
		.right{
			width: calc(100% - 320px);
			padding:16px 24px;
			.c_name{
				padding: 10px 0;
				font-size: 14px;
				color: #8993A4;
			}
			.p_name{
				color: #2F3941;
				font-size: 18px;
				font-weight: bold;
				padding: 8px 0 24px 0;
			}
			.eBtns{
				.el-button{
				    padding: 10px 18px;
				}
			}
			.tbBox{
				margin-top: 12px;
				position: relative;
				.diyCkWarp{
					position: absolute;
					top: 20px;
					left: 24px;
				}
			}
		}
		
		.tbTreeWarp{
			margin-top: 4px;
			height: 598px;
			overflow-y: auto;
			
		}
		.tbTreeWarp.tbTreeWarp2{
			height: 540px;
		}
		.diyTbTree{
			width: 100%;
			.treeNode {
			    flex: 1;
			    display: flex;
			    align-items: center;
			    justify-content: space-between;
			    font-size: 14px;
			    padding-right: 8px;
			  }
			.n_name{
				display: inline-block;
				width: calc(100% - 160px);
			}
			.n_btns{
				display: inline-block;
				width: 160px;
			}
			.treeNode{
				
			}
		}
	}
</style>
<style type="text/css">
	.tbTreeCheck thead .el-checkbox{
		display: none;
	}
	.spaceBox .m-body{
		padding: 0 !important;
	}
	/*.spaceBox .el-tree-node.is-current{
		color: #1A4CEC;
	}*/
	.diyTbTree{
		.el-tree-node__content{
		    height: 48px;
			border-bottom: 1px solid #EBEEF5;
		}
	}
	.treeWarp{
		.el-tree-node__content{
		    height: 40px;
		}
	}
	.dfNode1{
		.el-tree-node__label{
			color: #1A4CEC;
		}
	}
	.drBtn.el-button--text{
			color:#2F3941 !important;
		}
</style>