<template>
	<div class="Y-tips">
		<el-dialog class='diyModel' :title="dataId?'编辑服务项':'添加服务项'" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="528px" :before-close="closeMypup">
			<div >
				<TForm  ref="htmlFrom" lableRow :autoCheck='true' labelPosition='left' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'>
					
				
				</TForm>
				
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'> 确认</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	import {getCompanyList,getProjectList} from "@/api/business/order/tenant/order";
	export default {
		props: {
			dataId: {
				type: String|Number,
				default: '',
			},
			serviceTypeId: {
				type: String|Number,
				default: '',
			},
		},
		components: {TForm},
		data() {
			return {
				htmlDatas:{
  			},
  			isHttping:false,
  			formlist:[
//				{name: '服务类型',keys: 'serviceContent',value: '',type: 'input',flex:24,isMust:true,maxlen:15},
  				{name: '服务项目',keys: 'serviceContent',value: '',type: 'input',flex:24,isMust:true,maxlen:30},
  				{name: '收费标准',keys: 'price',value: '',type: 'input',flex:24,isMust:true,maxlen:10,tips:'(*元)'},
  				{name: '计量单位',keys: 'serviceUnit',value: '',type: 'input',flex:24,isMust:true,maxlen:10,tips:'(个、把、条...)'},
  			],
			}
		},
		
		computed: {},
		watch:{
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.$nextTick(() => {
						this.$refs.htmlFrom.resetFields();
					})
				}
			},
			
		},
		created() {
			this.getDatasById();
//			this.initProjectArrs();
		},
		mounted() {},
		updated() {},
		beforeDestroy() {},
		methods: {
			async getDatasById(){
				if(this.dataId){
					let params={
						paidServiceId:this.dataId,
					}
					let res = await this.ApiHttp('/base/tenant/paidService/findPaidServiceById',params);
					if(res){
						this.htmlDatas={...this.htmlDatas,...res}
					}
				}else{
					//没有ID 代表新增，重置输入框
					this.resetTFrom()	
				}
			},
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
			closeMypup(){
				this.resetTFrom();
				this.$emit('close')
			},
			initProjectArrs(){
				//加载项目数据
				getProjectList({
						companyId: null
					}).then((res) => {
					res.map(it=>{
						it.name = it.projectName;
						it.code = it.projectId;
					})
					this.common.insertOptions(this.formlist,'projectId',res);//添加选项数据到所属项目
				});
			},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
				
//				
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				let params={
					serviceTypeId:this.serviceTypeId,
					...this.htmlDatas,
				}
				let urlType='createPaidService'
				if(this.dataId){
					params
					urlType='updatePaidService';//编辑
				}
				console.log('AAAA',params);
				let res = await this.ApiHttp('/base/tenant/paidService/'+urlType,params,'POST');
				if(res){
					this.$refs.htmlFrom.resetFields();
					this.$emit('close','init')
				}
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}

</style>