import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'
import {downTemplate} from '@/utils/download'

/**
 * 位置列表查询
 * @param params
 */
export function getPositionList() {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/position/list',
        method: 'get',
    })
}

/**
 * 位置列表查询
 * @param params
 */
export function getPositionById(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/position/get/'+params.positionId,
        method: 'get',
    })
}

/**
 * 创建位置
 * @param params
 */
export function createPosition(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/position/create',
        method: 'post',
        data: params
    })
}

/**
 * 更新位置
 * @param params
 */
export function updatePosition(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/position/update',
        method: 'post',
        data: params
    })
}

/**
 * 删除位置
 * @param params
 */
export function deletePosition(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/position/delete',
        method: 'delete',
        data : params
    })
}

/**
 * 导出位置
 * @param params
 */
export function exportPosition(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/position/export',
        method: 'get',
        params
    })
}

/**
 * 下载设备模版
 */
export function downEmployeeTemplate() {
    downTemplate("ruge.position", envInfo.bgApp.basePath);
}

/**
 * 查询位置标签
 * @param params
 */
export function findPositionLabel(params) {
    return request({
        url: envInfo.bgApp.lookupPath + `/lookup/item/find/list?classifyCode=${params.classifyCode}`,
        method: 'get',
    })
}

/**
 * 查询项目列表
 * @param params
 */
export function getProjectList() {
    return request({
        url: envInfo.bgApp.basePath + `/tenant/project/list`,
        method: 'get',
    })
}

/**
 * 查询位置树
 */
export function getPositionTree(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/position/findPositionTree',
        method: 'get',
        params
    })
}
